import { ItemList } from "../orm";

class TrolleyProcessPayment extends ItemList {
  getEntityKey() {
    return "customer/purchased_trolley/process_payment";
  }

  pay(values) {
    return this.saveNewObject(values);
  }
}

export const trolleyProcessPayment = new TrolleyProcessPayment(
  "trolley_process_payment_default",
);
