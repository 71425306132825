import map from "lodash/map";
import get from "lodash/get";

import Checkbox from "@mui/material/Checkbox";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import React from "react";
import Typography from "@mui/material/Typography";

import { getLabelFromOptions } from '../eventpage/eventPageUtils'
import { makeLogger } from "../../utils/o11y/logger";
import { countryDropdownOptions } from '../../v2_actions/dropdown_options'
import { styles } from "./styles";
import { useReduxStateReconcilation } from "../hooks/useReduxStateReconcilation";
import { useGalleryFilterSearchParams } from "../hooks/useGalleryFilterSearchParams";

const logger = makeLogger({
  enabled: true,
  label: "FilterCountry",
  color: "pink",
});

export default function FilterCountry() {
  useReduxStateReconcilation();
  const [galleryFilterSearchParams, syncGalleryFilterSearchParams] =
    useGalleryFilterSearchParams();
  const [muiMenuAnchor, setMuiMenuAnchor] = React.useState(null);
  const isMenuOpen = Boolean(muiMenuAnchor);
  const countryOptions = countryDropdownOptions.getAsOptions()
  const countryFilterFromSearchParam = galleryFilterSearchParams.country

  logger.log({ countryOptions, countryFilterFromSearchParam })

  const handleClickListItem = (event) => {
    setMuiMenuAnchor(event.currentTarget);
  };

  const handleMenuItemClick = (option, index) => {
    const country = option?.value
    syncGalleryFilterSearchParams({ country: country })
    setMuiMenuAnchor(null);
  };

  const handleClose = () => {
    setMuiMenuAnchor(null);
  };

  const filterLabel = getLabelFromOptions(countryFilterFromSearchParam, countryDropdownOptions)

  const isFilterActive = !!countryFilterFromSearchParam
  
  return (
    <div>
      <Typography
        button
        sx={styles.filterListTitle}
        onClick={handleClickListItem}
      >
        Country
      </Typography>
      <List component="nav" sx={styles.filterList}>
        <ListItem
          button
          sx={styles.filterListItem}
          aria-expanded={isMenuOpen ? "true" : undefined}
        >
          <Checkbox checked={isFilterActive} size="small" />
          <ListItemText
            onClick={handleClickListItem}
            primary=""
            sx={styles.filterListItemText}
            secondary={<ListItemText primary={filterLabel} />}
          />
        </ListItem>
      </List>
      <Menu
        id="lock-menu"
        anchorEl={muiMenuAnchor}
        open={isMenuOpen}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "lock-button",
          role: "listbox",
        }}
      >
        <MenuItem
          key={"all"}
          disabled={countryFilterFromSearchParam === undefined}
          selected={countryFilterFromSearchParam === undefined}
          onClick={(event) => handleMenuItemClick(null, 0)}
        >
          {"All"}
        </MenuItem>
        {map(countryOptions, (option, index) => (
          <MenuItem
            key={get(option, "value")}
            disabled={countryFilterFromSearchParam === get(option, "value")}
            selected={countryFilterFromSearchParam === get(option, "value")}
            onClick={(event) => handleMenuItemClick(option, index)}
          >
            {get(option, "label")}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
