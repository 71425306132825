import { ItemList } from '../orm'

class RevolutTrolley extends ItemList {
    getEntityKey() {
        return "customer/purchased_trolley/prepare_revolut_order"
    }

    prepareOrder({ trolley_id, total_price }) {
      return this.saveNewObject({
        trolley: trolley_id,
        total_price_in_cents: total_price,
      })
    }
    
}

export const revolutTrolley = new RevolutTrolley("revolut_trolley_default")


