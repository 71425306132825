import { useEffect, useState } from "react";

import { activityTagsDropdownOptions } from "../../v2_actions/dropdown_options";
import { albumListForEventTab } from "../../v2_actions/customer_album";
import { briefGalleryList } from "../../v2_actions/brief_gallery";
import { countryDropdownOptions } from "../../v2_actions/dropdown_options";
import { countryList } from "../../v2_actions/country";
import { galleryEventDropdownOptions } from "../../v2_actions/dropdown_options";
import { galleryEventList } from "../../v2_actions/gallery_event";
import { photographerDropdownOptions } from "../../v2_actions/dropdown_options";
import { useDispatch } from "react-redux";
import { useReduxStateReconcilation } from "./useReduxStateReconcilation";

const GALLERY_EVENT_PAGE_SIZE = 50;
const ALBUM_PAGE_SIZE = 90;
const BRIEF_GALLERY_PAGE_SIZE = 90;

/**
 * Forces load of static gallery/filter data
 * required for events page / filtering to work.
 *
 * This is a UI blocking effect, removing it will
 * cause inconsistent page loads
 */
export const useEventsPageStartupEffects = () => {
  useReduxStateReconcilation();
  const dispatch = useDispatch();
  const activityOptionsLoading = Boolean(
    activityTagsDropdownOptions.isLoading(),
  );
  const countryOptionsLoading = Boolean(countryDropdownOptions.isLoading());
  const photographerOptionsLoading = Boolean(
    photographerDropdownOptions.isLoading(),
  );
  const galleryEventOptionsLoading = Boolean(
    galleryEventDropdownOptions.isLoading(),
  );

  useEffect(() => {
    dispatch(
      galleryEventList.updatePaginationNumItemsPerPage(GALLERY_EVENT_PAGE_SIZE),
    );
    dispatch(
      albumListForEventTab.updatePaginationNumItemsPerPage(ALBUM_PAGE_SIZE),
    );
    dispatch(
      briefGalleryList.updatePaginationNumItemsPerPage(BRIEF_GALLERY_PAGE_SIZE),
    );
    dispatch(activityTagsDropdownOptions.updatePaginationNumItemsPerPage(1000));
    dispatch(
      activityTagsDropdownOptions.fetchListIfNeeded({
        forceUpdate: undefined,
        callSource: "StartupFx",
      }),
    );
    dispatch(photographerDropdownOptions.updatePaginationNumItemsPerPage(1000));
    dispatch(
      photographerDropdownOptions.fetchListIfNeeded({
        forceUpdate: undefined,
        callSource: "StartupFx",
      }),
    );
    dispatch(countryDropdownOptions.updatePaginationNumItemsPerPage(1000));
    dispatch(
      countryDropdownOptions.fetchListIfNeeded({
        forceUpdate: undefined,
        callSource: "StartupFx",
      }),
    );
    dispatch(galleryEventDropdownOptions.updatePaginationNumItemsPerPage(1000));
    dispatch(
      galleryEventDropdownOptions.fetchListIfNeeded({
        forceUpdate: undefined,
        callSource: "StartupFx",
      }),
    );
  }, []);

  return (
    galleryEventOptionsLoading ||
    activityOptionsLoading ||
    countryOptionsLoading ||
    photographerOptionsLoading
  );
};
