import { Header, Pill, PillBar } from "bossfotos-design-system";
import {
  getDateLabel,
  getLabelFromOptions,
} from "../../v2_components/eventpage/eventPageUtils";
import {
  getIsEventIframe,
  getIsPhotographerIframe,
  isWindowInIframe,
} from "../../v2_actions/nav";
import { useNavigate, useParams } from "react-router-dom";

import { briefGalleryList } from "../../v2_actions/brief_gallery";
import { makeLogger } from "../../utils/o11y/logger";
import { replaceDashesWithSpaces } from "../../v2_actions/nav";
import { useCountryDetection } from "../../v2_components/hooks/useCountryDetection";
import {
  countryDropdownOptions,
  activityTagsDropdownOptions,
  photographerDropdownOptions,
} from '../../v2_actions/dropdown_options'
import { useEventFiltersDispatcher } from "../../v2_components/hooks/useEventFiltersDispatcher";
import { useGalleryFilterSearchParams } from "../../v2_components/hooks/useGalleryFilterSearchParams";
import { useRouteInfo } from "../../v2_components/hooks/useRouteInfo";

const logger = makeLogger({
  enabled: true,
  label: "ActiveFilterPills",
  color: "red",
});

export const ActiveFilterPills = () => {
  const navigate = useNavigate();
  const { isMapView } = useRouteInfo();
  const [
    galleryFilterSearchParams,
    syncGalleryFilterSearchParams,
    urlSearchParams,
  ] = useGalleryFilterSearchParams();
  const eventPageCtx = useEventFiltersDispatcher();
  const { gallery_event_name, album_name } = useParams();
  const isMapFilterActive = briefGalleryList.isMapFilterActive();
  const {
    countryCode,
    persistCountry,
    getPreferredCountryApiId,
  } = useCountryDetection();
  const isInIframe = isWindowInIframe();
  const isEventIframe = getIsEventIframe();
  const isPhotographerIframe = getIsPhotographerIframe();
  const showCountry = !isInIframe;
  const showEvent = !isEventIframe;
  const showPhotographer = !isPhotographerIframe;

  const clearSingleFilter = (filterType: string) => {
    logger.log("clearSingleFilter", filterType);

    if (filterType === "___map") {
      eventPageCtx.updateAllListFilters({
        north_east_lat: undefined,
        north_east_lng: undefined,
        south_west_lat: undefined,
        south_west_lng: undefined,
        country: getPreferredCountryApiId(),
      });
      return;
    }
    if (filterType === "event") {
      const redirectUrl = isMapView
        ? `/customer/map/?${urlSearchParams}`
        : `/customer/events/?${urlSearchParams}`;
      logger.log(redirectUrl);
      navigate(redirectUrl);
      return;
    }
    syncGalleryFilterSearchParams({
      [filterType]: undefined,
    });
  };

  const filters = () => {
    const filterInfos = [];
    if (showCountry && !isMapView) {
      if (isMapFilterActive) {
        filterInfos.push({ type: "___map", value: "Set by map" });
      } else if (galleryFilterSearchParams.country) {
        filterInfos.push({
          type: "country",
          value: getLabelFromOptions(galleryFilterSearchParams.country, countryDropdownOptions),
        });
      }
    }
    if (showEvent && gallery_event_name) {
      filterInfos.push({
        type: "event",
        value: replaceDashesWithSpaces(gallery_event_name),
      });
    }
    if (galleryFilterSearchParams.date) {
      filterInfos.push({
        type: "date",
        value: getDateLabel(galleryFilterSearchParams.date),
      });
    }
    if (showPhotographer && galleryFilterSearchParams.photographers) {
      filterInfos.push({
        type: "photographers",
        value: getLabelFromOptions(
          galleryFilterSearchParams.photographers, photographerDropdownOptions
        ),
      });
    }
    if (galleryFilterSearchParams.activity) {
      filterInfos.push({
        type: "activity",
        value: getLabelFromOptions(galleryFilterSearchParams.activity, activityTagsDropdownOptions),
      });
    }

    return filterInfos;
  };
  const activeFilters = filters();

  if (activeFilters.length > 0) {
    return (
      <Header>
        <PillBar>
          {activeFilters.map((filter, i) => (
            <Pill
              key={`pill-${i}`}
              onClear={() => clearSingleFilter(filter.type)}
            >
              {filter.value}
            </Pill>
          ))}
        </PillBar>
      </Header>
    );
  }

  return <></>;
};
