import moment from "moment";
import get from "lodash/get";
import first from "lodash/first";
import { DATE_FILTER_OPTIONS } from "../../v2_actions/date";
import { ItemList } from '../../orm'

// remove this for event filtering
const DATE_FILTER_OPTIONS_FILTERED = DATE_FILTER_OPTIONS.filter(
  (item) => item.value !== "placeholder_date_filter_option",
);

export function mapDateRange(dateRange: string | undefined) {
  switch (dateRange) {
    case "last_24_hrs":
      return { from: moment().format(), to: moment().format() };
    case "last_2_days":
      return {
        from: moment().subtract(2, "days").format(),
        to: moment().format(),
      };
    case "last_7_days":
      return {
        from: moment().subtract(7, "days").format(),
        to: moment().format(),
      };
    case "last_30_days":
      return {
        from: moment().subtract(31, "days").format(),
        to: moment().format(),
      };
    case "last_90_days":
      return {
        from: moment().subtract(90, "days").format(),
        to: moment().format(),
      };
    case "last_180_days":
      return {
        from: moment().subtract(180, "days").format(),
        to: moment().format(),
      };
    case "last_365_days":
      return {
        from: moment().subtract(365, "days").format(),
        to: moment().format(),
      };
    case "last_2_years":
      return {
        from: moment().subtract(2, "years").format(),
        to: moment().format(),
      };
    case "last_3_years":
      return {
        from: moment().subtract(3, "years").format(),
        to: moment().format(),
      };
    case "custom":
    case undefined:
      return { from: undefined, to: undefined };
      return { from: undefined, to: undefined };
    default:
      if (dateRange !== get(first(DATE_FILTER_OPTIONS_FILTERED), "value")) {
        console.error(`Unknown date filter option: ${dateRange}`);
      }
      return { from: undefined, to: undefined };
  }
}

export const DEFAULT_COUNTRY = {
  label: "Australia",
  value: "f57f2201-0af1-4810-bdba-e483992e5229",
};

export const DisplayMode = {
  GalleryEvent: "gallery_event",
  Album: "album",
  Gallery: "gallery",
};

export function deriveDisplayMode(
  gallery_event_name: string | undefined,
  album_name: string | undefined,
) {
  if (gallery_event_name && !album_name) {
    return DisplayMode.Gallery;
  } else if (gallery_event_name && album_name) {
    return DisplayMode.Album;
  }

  return DisplayMode.GalleryEvent;
}

export const getDateLabel = (value: string) =>
  DATE_FILTER_OPTIONS_FILTERED.filter((opt) => opt.value === value)
    .map((opt) => opt.label)
    .concat("Recent")
    .shift();

export const getLabelFromOptions = (value: string, itemList: ItemList) => {
  const options = itemList.getAsOptions()

  return options
    .filter((opt) => opt.value === value)
    .map((opt) => opt.label)
    .concat("All")
    .shift();
}
