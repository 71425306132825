import {
  Button,
  Inline,
  InputTextContainer,
  InputTextInner,
  ModalContent,
  ModalCover,
  ModalFooter,
  ModalWrapper,
  Stack,
} from "bossfotos-design-system";
import { useState } from "react";
import { useIsMobile } from "../../actions/ui";

export const GratuityModal = ({
  onClose,
}: {
  onClose: (gratuityPercentage?: number | undefined) => void;
}) => {
  const isMobile = useIsMobile();

  return (
    <ModalCover tabIndex={0}>
      <ModalWrapper>
        <ModalContent>
          <Stack
            style={{
              width: isMobile ? "300px" : "400px",
              padding: "20px",
              paddingBottom: "50px",
              textAlign: "center",
            }}
          >
            <h3>Add a Gratuity</h3>
            <p>Would you like to add a gratuity for our hardworking photography team</p>
            <div>
              <Inline fill={true} style={{padding: "10px"}}>
                <Button fill={true} onClick={() => onClose(20)} variant="secondary">
                  20%
                </Button>
                <Button fill={true} onClick={() => onClose(15)} variant="secondary">
                  15%
                </Button>
              </Inline>
              <Inline fill={true} style={{padding: "10px"}}>
                <Button fill={true} onClick={() => onClose(10)} variant="secondary">
                  10%
                </Button>
                <Button fill={true} onClick={() => onClose(0)} variant="secondary">
                  0%
                </Button>
              </Inline>
            </div>
          </Stack>
        </ModalContent>
      </ModalWrapper>
    </ModalCover>
  );
};

export default GratuityModal;
