import map from "lodash/map";

import get from "lodash/get";
import React from "react";
import Checkbox from "@mui/material/Checkbox";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";

import { getLabelFromOptions } from "../../v2_components/eventpage/eventPageUtils";
import { makeLogger } from "../../utils/o11y/logger";
import { photographerDropdownOptions } from "../../v2_actions/dropdown_options";
import { styles } from "./styles";
import { useGalleryFilterSearchParams } from "../hooks/useGalleryFilterSearchParams";

const logger = makeLogger({
  enabled: true,
  label: "FilterPhotographer",
  color: "pink",
});

export default function FilterPhotographer() {
  const [galleryFilterSearchParams, syncGalleryFilterSearchParam] =
    useGalleryFilterSearchParams();
  const [muiMenuAnchor, setMuiMenuAnchor] = React.useState(null);
  const isMenuOpen = Boolean(muiMenuAnchor);
  const photographerOptions = photographerDropdownOptions.getAsOptions();

  const photographerFilterFromSearchParam =
    galleryFilterSearchParams.photographers;

  logger.log("render()");

  const handleClickListItem = (event) => {
    setMuiMenuAnchor(event.currentTarget);
  };

  const handleCheckboxClicked = (ev) => {
    if (!ev.target.checked) {
      setMuiMenuAnchor(null);
      syncGalleryFilterSearchParam({ photographers: "" });
    }
  };

  const handleMenuItemClick = (option, index) => {
    syncGalleryFilterSearchParam({ photographers: option?.label });
    setMuiMenuAnchor(null);
  };

  const handleClose = () => {
    setMuiMenuAnchor(null);
  };

  const filterLabel = getLabelFromOptions(
    photographerFilterFromSearchParam, photographerDropdownOptions
  );

  const isFilterActive = !!photographerFilterFromSearchParam;

  return (
    <div>
      <Typography
        button
        sx={styles.filterListTitle}
        onClick={handleClickListItem}
      >
        Photographer
      </Typography>
      <List component="nav" sx={styles.filterList}>
        <ListItem
          button
          sx={styles.filterListItem}
          aria-expanded={isMenuOpen ? "true" : undefined}
        >
          <Checkbox
            checked={isFilterActive}
            size="small"
            onChange={handleCheckboxClicked}
          />
          <ListItemText
            onClick={handleClickListItem}
            primary=""
            sx={styles.filterListItemText}
            secondary={<ListItemText primary={filterLabel} />}
          />
        </ListItem>
      </List>
      <Menu
        anchorEl={muiMenuAnchor}
        open={isMenuOpen}
        onClose={handleClose}
        MenuListProps={{
          role: "listbox",
        }}
      >
        <MenuItem
          value={null}
          disabled={photographerFilterFromSearchParam === undefined}
          selected={photographerFilterFromSearchParam === undefined}
          onClick={(event) => handleMenuItemClick(null, 0)}
        >
          All
        </MenuItem>
        {map(photographerOptions, (option, index) => (
          <MenuItem
            key={get(option, "value")}
            disabled={
              photographerFilterFromSearchParam === get(option, "value")
            }
            selected={
              photographerFilterFromSearchParam === get(option, "value")
            }
            onClick={(event) => handleMenuItemClick(option, index)}
          >
            {get(option, "label")}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
